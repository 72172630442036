<template>
  <div class="section-content pt-4">
    <div ref="rmTopSection">
      <hb-header full>
        <hb-page-header
          title="Rent Management Center"
          description="Effectively track, monitor and manage your tenant's rent from one place."
        />
      </hb-header>
    </div>
    <div ref="rmPropertySection" class="d-flex align-center">
      <HbPropertySelector
        v-show="properties.length !== 1"
        v-model="property"
        :items="properties"
        item-value="id"
        item-text="name"
      />
    </div>
    <div>
      <div ref="rmTabSection">
        <hb-tabs v-model="activeTab">
          <v-tab v-for="item in tabOptions" :key="item.key">
            {{ item.label }}
            <hb-tooltip bottom>
              <template v-slot:item>
                <hb-icon class="ml-2" small>mdi-information</hb-icon>
              </template>
              <template v-slot:header>
                <span v-html="item.description" />
              </template>
            </hb-tooltip>
          </v-tab>
        </hb-tabs>
        <v-divider />
      </div>

      <v-tabs-items
        class="rm-tab-items"
        v-model="activeTab"
      >
        <v-tab-item class="tab-item pr-4">
          <rent-change-queue :rm-permissions="permissions" />
        </v-tab-item>
        <v-tab-item class="tab-item">
          <RentPlans :rm-permissions="permissions" />
        </v-tab-item>
        <v-tab-item class="tab-item pr-4">
          <tenant-rent-management :rm-permissions="permissions" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import RentPlans from "./RentPlans.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import { EventBus } from "../../../EventBus.js";
import RentChangeQueue from "./RentChangeQueue.vue";
import TenantRentManagement from "./TenantRentManagement.vue";

export default {
  name: "RentManagement",

  components: {
    RentChangeQueue,
    TenantRentManagement,
    RentPlans,
  },

  data() {
    return {
      tabOptions: [
        {
          label: "Rent Change Queue",
          key: 1,
          description:
            "The Rent Change Queue provides a comprehensive view of upcoming rent changes organized by deployment months. Easily monitor and control rent adjustments, including current, past, and upcoming rent changes, for a selected property within your preferred timeframe.",
        },
        {
          label: "View & Manage Rent Plans",
          key: 2,
          description:
            "Develop rent plans utilizing strategies such as rent caps and minimum rent increases, while specifying whether the rent increase is a one-time occurrence or recurring, on a corporate level. <br>Changes made to the plan will affect tenants in all properties associated with that plan.",
        },
        {
          label: "Rent Management by Tenant",
          key: 3,
          description:
            "The Rent Management By Tenant feature offers a customizable tabular view of rent changes and tenant details within a selected property, simplifying rent tracking and tenant data management.",
        },
      ],
      activeTab: null,
      rentManagementPermissions: [
        "view_rent_plans",
        "manage_rent_plans",
        "exempt_rent_changes",
        "manage_rent_change_status",
        "approve_rent_changes",
        "manual_rent_changes",
        "tag_rent_changes",
        "assign_rent_plan",
      ],
      property: null,
      report_key: 0,
    };
  },
  created(){
    EventBus.$on('refetch_data', () => this.fetchData());
  },
  async mounted() {
    if (!this.showLocalPropertySelector) {
      this.property = this.selectedProperty;
      this.fetchPropertyRentSettings();
      this.fetchPropertySettings();
    }
    this.fetchRentPlans();
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      selectedProperty: "revManStore/getSelectedProperty",
      hasRolePermission: "authenticationStore/rolePermission",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
      companyRentSettings: "revManStore/companyRentSettings",
    }),
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
    permissions() {
      let rmPermission = {};
      this.rentManagementPermissions.forEach((permission) => {
        rmPermission[permission] = this.hasRolePermission(permission);
      });
      return rmPermission;
    },
  },
  watch: {
    property: async function (value) {
      await this.setProperty(value);
      await this.$store.dispatch("filterStore/getSelectedProperty", value);
      await this.$store.dispatch("propertiesStore/setBulkEditProperty", value);
      await this.fetchPropertyRentSettings();
      await this.fetchPropertySettings();
    },
  },
  async destroyed() {
    if (!this.$route.fullPath.includes("/rent-management")) {
      await this.setProperty(null);
      await this.$store.dispatch("filterStore/getSelectedProperty", null);
      await this.$store.dispatch("propertiesStore/setBulkEditProperty", null);
    }
    EventBus.$off('refetch_data', () => this.fetchData());
  },
  methods: {
    ...mapActions({
      fetchRentPlans: "revManStore/fetchRentPlans",
      setProperty: "revManStore/setProperty",
      fetchPropertyRentSettings:
        "revManStore/fetchCurrentPropertyRentConfiguration",
      fetchPropertySettings:
        "revManStore/fetchCurrentPropertyRatePlanConfiguration",
    }),
    fetchData(){
      this.property = this.selectedProperty;
    }
  },
};
</script>
<style lang="scss" scoped>
.section-content {
  padding-right: 0px !important;
}
.rm-tab-items {
  background-color: #f9fafb;
}
</style>
